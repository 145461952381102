import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  NO_ACCESS_PAGE: '/noaccess',
  CONFIRM_DEMAND: '/demands/confirm-demand',
  SUPPLIER_INVITE: '/supplier-invite',
  SUPPLIER_REGISTRATION: "/supplier/registration"
};

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.NO_ACCESS_PAGE,
    component: lazy(() => import('@iso/containers/Pages/NoAccessPage/NoAccessPage')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SUPPLIER_INVITE,
    component: lazy(() =>
      import('@iso/containers/Pages/Supplier/Invitation')
    ),
  },
  {
    path: PUBLIC_ROUTE.SUPPLIER_REGISTRATION,
    component: lazy(() =>
      import('@iso/containers/Pages/Supplier/Registration')
    ),
  }
];

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        children
      }
    />
  );
}

export default function Routes(props) {
  const { isUILocked } = props;

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
